import React, { useEffect, useState } from "react";
import FaucetUI from "./FacuetUI";
import AlertView from "./AlertView";
import LOGO from "./assets/logo.png";

function App() {
  const [chains, setChains] = useState([]);
  const [sitekey, setSitekey] = useState("");
  const [token, setToken] = useState("");
  const [idempotencyKey, setIdempotencyKey] = useState(`${Date.now()}`);
  const [error, setError] = useState(null);
  const [alert, setAlert] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [addressVerfy, setAddressVerfy] = useState(false);
  const [curUserIsVerfy, setCurUserIsVerfy] = useState(false);
  const [address, setAddress] = useState("");
  const [selected, setSelected] = useState(null);

  const loadChains = async () => {
    return fetch("/api/v1/faucet/chains").then((response) => response.json());
  };

  function hasNoChineseCharacters(str) {
    return !/[\u4e00-\u9fa5]/.test(str);
  }
  const loadSitekey = async () => {
    return fetch("/api/v1/faucet/sitekey").then((response) => response.json());
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSending(true);
    setSuccess(null);
    setAlert(null);
    let payload = {
      address: address,
      chainId: selected.chainId,
      token: token,
      idempotency_key: idempotencyKey,
    };
    const response = await fetch("/api/v1/faucet/give_me", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    let res_json = await response.json();

    setIsSending(false);
    response.status === 200
      ? setSuccess(
          `Success! a <strong>0.01 NUB</strong> treat is on the way to <strong>${address}</strong> . The transaction hash is <strong>${res_json?.txHash}</strong>. For more transaction details, please visit our explorer at  <a href="https://explorer.nubit.org" style="text-decoration: underline" target="_blank">https://explorer.nubit.org</a>. Please note that each device and IP address can only claim once every 24 hours.`
        )
      : setAlert(res_json.message);
    response.status === 200 ? setAlert(null) : setSuccess(null);
  };

  const getExtraNubHandle = async () => {
    setIsSending(true);
    setSuccess(null);
    setAlert(null);

    let payload = {
      address,
      chainId: selected.chainId,
      token,
      idempotency_key: idempotencyKey,
    };
    const response = await fetch("/api/v1/faucet/extra_nub", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    let res_json = await response.json();

    setIsSending(false);
    response.status === 200
      ? setSuccess(
          `Success! a <strong>0.02 NUB</strong> treat is on the way to <strong>${address}</strong> . The transaction hash is <strong>${res_json?.txHash}</strong>. For more transaction details, please visit our explorer at  <a href="https://explorer.nubit.org" style="text-decoration: underline" target="_blank">https://explorer.nubit.org</a>. Please note that each device and IP address can only claim once every 24 hours.`
        )
      : setAlert(res_json.message);
    response.status === 200 ? setAlert(null) : setSuccess(null);
  };

  const getCheckUserIsVerfy = async () => {
    const response = await fetch("/api/v1/faucet/allow_extra", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    let CheckUser_json = await response.json();
    if(CheckUser_json?.allow) {
      setCurUserIsVerfy(true)
    } else {
      setCurUserIsVerfy(false)
    }
  };

  useEffect(() => {
    // test mock data
    // setChains([{ name: "nubit", chainId: "alpha-testnet" }]);
    // setSelected([{ name: "nubit", chainId: "alpha-testnet" }]);

    loadChains()
      .then((res) => {
        setChains(res.chains);
        setSelected(res.chains[0]);
      })
      .catch((err) => {
        setError("Server is not available,Please check your network!");
      });

    if (address === "" || address == undefined || address === null) {
      setIsSending(true);
    }

    setToken("");
    setIdempotencyKey(`${Date.now()}`);
    loadSitekey()
      .then((res) => {
        setSitekey(res?.sitekey);
      })
      .catch((err) => {
        console.log(err);
      });

    getCheckUserIsVerfy();
  }, []);

  if (error != null) {
    return (
      <div className="bg-slate-800 h-screen">
        <div className="header">
          <img src={LOGO} />
        </div>
        <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="infomation sm:mx-auto sm:w-full sm:max-w-md">
            <AlertView message={error} type="error" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-slate-800 h-screen">
      <div className="header">
        <img src={LOGO} />
      </div>
      <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="infomation sm:mx-auto sm:w-full sm:max-w-md">
          {error && <AlertView message={error} type="error" />}
          {alert && <AlertView message={alert} type="warning" />}
          {success && <AlertView message={success} type="success" />}
          {isSending && addressVerfy && (
            <AlertView
              message={"Transaction is being sent to the server"}
              type="warning"
            />
          )}
        </div>
        {chains.length === 0 && (
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-slate-700 py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <div className="space-y-6 text-white">No chains available</div>
              </div>
            </div>
          </div>
        )}
        <FaucetUI
          isSending={isSending}
          chains={chains}
          selectedChain={selected}
          handleSelectChain={setSelected}
          address={address}
          onAddressChanged={(value) => {
            // address check
            if (value === "" || value === null || value === undefined) {
              setAddress('');
              setAddressVerfy(false)
              return;
            } else {
              if (!hasNoChineseCharacters(value?.trim())) {
                setIsSending(true);
                setAddressVerfy(false)
              } else {
                setAddress(value?.trim())
                setIsSending(false);
                setAddressVerfy(true)
              }
              if ((value?.trim()).length === 44) {
                setAddress(value?.trim())
                setIsSending(false);
                setAddressVerfy(true)
              } else {
                setIsSending(true);
                setAddressVerfy(false)
              }
            }
          }}
          handleSubmit={handleSubmit}
          sitekey={sitekey}
          getToken={(token_) => {
            setToken(token_);
          }}
          token={token}
          idempotencyKey={idempotencyKey}
          getExtraNubHandle={() => getExtraNubHandle()}
          curUserIsVerfy={curUserIsVerfy}
          curUserIsVerfyChange={()=> setCurUserIsVerfy(!curUserIsVerfy)}
        />
      </div>
    </div>
  );
}

export default App;
