import React, { useEffect, useState } from "react";
import SelectChainUI from "./SelectChainUI";
import { Turnstile } from "@marsidev/react-turnstile";
import clsx from "clsx";
import Twitter from "./assets/twitter.svg";
import BlockLink from "./assets/blockLink.svg";
import Info from "./assets/info.svg";
import Yes from "./assets/yes.png";

export default function FaucetUI({
  isSending,
  chains,
  selectedChain,
  handleSelectChain,
  address,
  onAddressChanged,
  handleSubmit,
  sitekey,
  getToken,
  token,
  idempotencyKey,
  getExtraNubHandle,
  curUserIsVerfy,
  curUserIsVerfyChange,
}) {
  const [unlockStatus, setUnlockStatus] = useState(false);
  const [getVerifyStatus, setGetVerifyStatus] = useState(false);
  const [tweetLinkStatus, setTweetLinkStatus] = useState(0); // 0 1 2
  const [tweetLinkInfo, setTweetLinkStatusInfo] = useState("");
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (curUserIsVerfy) {
      setTweetLinkStatus(1);
      setUnlockStatus(true);
    }
  }, [curUserIsVerfy]);

  const UnlockExtraBonus = () => {
    setUnlockStatus(true);
  };

  const getShareStr = () => {
    return `Secured by Bitcoin, Nubit is creating the most secure and scalable data availability layer for multi-chain ecosystems. #NubitAlpha #BitcoinDA

Join the revolution: alpha.nubit.org!`;
  };

  const onInputChange = (value) => {
    setInputValue(value);
  };

  const searchFor = async () => {
    if (inputValue === "" || inputValue === undefined || inputValue === null)
      return;
    setGetVerifyStatus(true);
    const response = await fetch(
      `/api/v1/faucet/verify?url=${inputValue}&address=${address}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    let veryRes = await response.json();
    // res check
    // let veryRes = {
    //   msg: "success" || "fail",
    // };
    if (veryRes?.msg == "success") {
      curUserIsVerfyChange();
      setTweetLinkStatus(1);
      setTweetLinkStatusInfo("success");
    } else {
      setTweetLinkStatus(2);
      setTweetLinkStatusInfo("Invalid Tweet Link");
    }
    setGetVerifyStatus(false);
  };

  return (
    <div className="formContent mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-slate-700 py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <div className="formContent_title">NUBIT FAUCET</div>
        <div className="formContent_desc">Get NUB tokens for Nubit DA</div>
        <form className="space-y-6" action="#" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-white"
            >
              Chain
            </label>
            <div className="mt-1">
              <SelectChainUI
                chains={chains}
                selectedChain={selectedChain}
                handleSelectChain={handleSelectChain}
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="address"
              className="block text-sm font-medium text-white"
            >
              Nubit address
            </label>
            <div className="mt-1">
              <input
                id="address"
                name="address"
                type="text"
                placeholder="nubit..."
                required
                className="nubitAddress block w-full bg-slate-800 text-white appearance-none rounded-md border border-slate-900 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={address}
                onChange={(e) => onAddressChanged(e.target.value)}
              />
            </div>
          </div>
          <div className="Turnstile">
            <Turnstile
              siteKey={sitekey || "0x4AAAAAAAc0-2__wJ6eeI4X"}
              language={"auto"}
              theme={"dark"}
              onSuccess={(res) => {
                getToken(res);
              }}
              onExpire={(res) => {
                getToken("");
              }}
            />
          </div>
          <div className="submitButton">
            <button
              type="submit"
              className={clsx(
                "flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
                {
                  "opacity-50 cursor-not-allowed": isSending || token == "",
                }
              )}
              disabled={isSending || token == ""}
            >
              Get NUB
            </button>
          </div>
        </form>

        {/* Bonus */}
        {tweetLinkStatus !== 1 && (
          <div
            className={`UnlockExtraBonus ${
              isSending || token == "" ? "UnClockGet" : ""
            }`}
            onClick={() => {
              if (isSending || token == "") return;
              UnlockExtraBonus();
            }}
          >
            Unlock Extra Bonus
          </div>
        )}
        {tweetLinkStatus === 1 && (
          <div
            className={`UnlockExtraBonus getSuccess ${
              isSending || token == "" ? "UnClockGet" : ""
            }`}
            onClick={() => {
              if (isSending || token == "") return;
              getExtraNubHandle();
            }}
          >
            Get Extra NUB
          </div>
        )}

        {unlockStatus && (
          <>
            <div className="bonusLine" />
            <div className="bonusTitle">
              <img width={27} height={27} src={Twitter} />
              Tweet to Get Extra NUB
            </div>

            <div className="step_1">
              1. Follow{" "}
              <a
                className="nubit_org_link"
                target="_blank"
                href="https://twitter.com/intent/user?screen_name=nubit_org"
              >
                @nubit_org
              </a>{" "}
              on X (Twitter).
            </div>
            <div className="step_2">
              2. Copy the following message and share it on X (Twitter).
              <a
                target="_blank"
                className=""
                href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                  getShareStr()
                )}`}
              >
                <img width={13} height={13} src={BlockLink} />
              </a>
            </div>
            <div className="step_2_desc">
              <div>
                Secured by Bitcoin, Nubit is creating the most secure and
                scalable data availability layer for multi-chain ecosystems.
                <span style={{ color: "#F33928", marginLeft: "6px" }}>
                  #NubitAlpha #BitcoinDA
                </span>
              </div>
              <div>Join the revolution: alpha.nubit.org!</div>
            </div>

            <div className="step_3">
              3. Return to this page and paste the tweet link in the field
              below.
            </div>
            <div className="step_3_desc">
              <input
                placeholder="Paste the X (Twitter) Link"
                onChange={(e) => onInputChange(e?.target?.value)}
              />
              {!curUserIsVerfy && (
                <div
                  className={`verifyTweetBtn ${
                    (inputValue === "" ||
                      inputValue === undefined ||
                      inputValue === null ||
                      getVerifyStatus) &&
                    "verifyTweetBtnNot"
                  }`}
                  onClick={() => {
                    if (getVerifyStatus) return;
                    searchFor();
                  }}
                >
                  Verify Tweet
                </div>
              )}
              {curUserIsVerfy && (
                <div className={`verifyTweetBtn verifyTweetBtnNot`}>
                  Verify Tweet
                </div>
              )}
            </div>
            {curUserIsVerfy && (
              <div className="notes_success_curUserIsVerfy">
                You have unlocked the Extra Bonus. The next verification can
                only be done after 24 hours.
              </div>
            )}
            {/* success */}
            {tweetLinkStatus === 1 && !curUserIsVerfy && (
              <div className="notes_success">
                <img width={16} height={16} src={Yes} />
                {tweetLinkInfo}
              </div>
            )}

            {/* error */}
            {tweetLinkStatus === 2 && !curUserIsVerfy && (
              <div className="notes_error">
                <img width={16} height={16} src={Info} />
                {tweetLinkInfo}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
